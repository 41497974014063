@font-face {
    font-family: "Lato";
    src: url('../../static/fonts/Lato-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

.pin {
    display: none;
}

@media (max-height: 375px) {
    .pin {
        display: none !important;
    }

    .sideDrawer ul li a {
        font-size: 16px !important;
    }
}

@media (max-width: 769px) {
    .pin {
        margin: 0 auto;
        margin-bottom: 20px;
        width: 100px;
        height: 125px;
        display: block;
    }

    .sideDrawer {
        text-align: center;
        width: 100% !important;
        padding-right: 0 !important;
        
    }

    .sideDrawer ul {
        padding-right: 0 !important;
    }

    .sideDrawer ul li:first-child{
        margin-top: 0;
    }

    .sideDrawer ul li {
        width: 100% !important;
    }

    .sideDrawer .meta {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .sideDrawer ul {
        padding-left: 0 !important;
    }
}


.sideDrawer {
    font-size: 16px;
    font-weight: 300;
    font-family: 'Lato', sans-serif;
    height: calc(100% - 64px);
    position: absolute;
    left:0;
    top: 64px;
    z-index: 999;
    background-color: white;
    width: 300px;
    display: flex;
    flex-direction: column;
}

.sideDrawer::backdrop {
    background: rgba(255,0,0,.25);
  }

.sideDrawer .red {
    color: #B52D20;
}

.sideDrawer .meta {
    color: #6F7E84;
    padding: 20px;
}

.sideDrawer ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.sideDrawer .spacer {
    flex: 1;
}

.sideDrawer li {
    margin-top: 25px;
}

.sideDrawer a {
    color: #6F7E84;
    font-size: 20px;
    font-weight: 300;
}