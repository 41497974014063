.header {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    padding: 15px 25px;
    height: 64px;
    display: flex;
    align-items: center;
    position: relative;

    a {
        color: var(--color-red);
    }

    .link {
        outline: none;
        color: var(--color-red);
        transition: 0.15s color linear, 0.2s opacity linear;
        opacity: 0;
        margin-right: 39px;
        line-height: 1.4;

        &.link-visible {
            opacity: 1;
        }

        &:hover {
            color: var(--color-red-dark);
        }

        &:active {
            color: var(--color-red-dark);
        }

        span {
            white-space: nowrap;
        }
    }

    .count {
        transition: opacity 0.2s linear;
        opacity: 0;
        position: absolute;

        &.count-visible {
            opacity: 1;
        }
    }
}
