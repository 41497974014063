
input {
    border:none;
    padding: 5px 20px;
    width: 500px;
    outline: none;
    font-size: 20px;
    margin-right: 5px;
    color: #6F7E84;
    border-bottom: 1px solid #6F7E84;
    background: url("/magnifier.svg") no-repeat right top;
    background-position: right 5px;

    @media screen and (max-width: 570px) {
        width:270px;
        margin-left:20px;
    }
}

input:not(:placeholder-shown) {
    background: none;
  }

.suggestionsContainer {
    background-color: white;
    position: absolute;
    top: 60px;
    width: 500px;
    z-index: 2;
    box-shadow: 6px 3px 6px #00000029;

    @media screen and (max-width: 570px) {
        width: 270px;
        margin-left:20px;
    }
}

.suggestionsList {
    list-style: none;
    cursor: pointer;
    max-height: 500px;
    overflow-y: auto;
}

.suggestionsList li {
    margin-bottom: 10px;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-right: 10px;
}

.suggestionsList li + li {
    border-top: 1px solid #6F7E84;
}