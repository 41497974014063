@font-face {
    font-family: "Lato";
    src: url('../../static/fonts/Lato-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

.suggestionItems {
    display: flex;
    justify-content: space-between;
}

.items {
    display: flex;
}

.logo{
    margin-left: 20px;
    margin-right: 20px;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo img {
    border-radius: 50%;
    border: 4px solid #B52D20;
    width: 45px;
    margin-top: 5px;
    height: 45px;
}

.suggestionItems {
    display: flex;
    flex-direction: column;
}

.suggestionItems .name {
    text-align: left;
    flex: 6;
}

.countMembers {
    margin-top: 0px;
    font-size: 12px;
}

.search {
    color: #6F7E84;
    display: flex;
    color: #6F7E84;
    justify-content: center;

}

.clearSearch {
    color: #6F7E84;
    margin-left: -25px;
    margin-top: -6px;
}