html {
    -webkit-text-size-adjust: 100%;
    height: 100%;
    font: var(--text-regular);
    color: var(--color-white);
}

body {
    margin: 0;
    height: 100%;
    background-color: var(--color-gray-dark);
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    font: inherit;
}

button {
    box-shadow: none;
    border: none;
    background-color: var(--color-none);
    text-transform: none;
    outline: 0;
    cursor: pointer;
    font: inherit;
    letter-spacing: inherit;

    &:disabled {
        cursor: default;
    }
}

.count_members {
    font-size: 12px;
    color: #999;
    display: block;
    margin: 5px 0 10px 0;
}

.avatar-box {

    &:hover {
        z-index: 9999999999 !important;
    }

    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-left: -4px;
    }

    .message {
        cursor: pointer;
        position: absolute;
        right: -10px;
        top: 24px;
        transform: translateX(100%) translateY(-50%);
        background-color: var(--color-white);
        color: var(--color-gray);
        padding: 10px;
        padding-top: 5px;
        /* padding-bottom: 0px; */
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 40px;
        font: var(--text-regular-small);
        font-size: 12px;
        min-width: 240px;
        max-width: 440px;
        word-break: break-word;
        box-shadow: 0 3px 12px 0 rgba(38, 47, 54, 0.5);

        @media screen and (max-width: 667px) {
            font: var(--text-regular-tiny);
        }

        &.small {
           font-size: 10px;
           min-width: 120px;
           opacity: 0.85;
        }

        .content {
            display: flex;
            align-items:center;

            .title {
                display: block;
                display: -webkit-box;
                margin-left: 10px;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .meta {
            margin-top: 5px;
            margin-bottom: 5px;
            display: flex;
            justify-content: space-around;
            font-size: 12px;
            font: var(--text-regular-small);
            
            .item {
                display: flex;
                align-items: center;
                font-size: 12px;
                color: black;
            }

            img { margin-right: 5px;}

            a {
                display: flex;
                align-items: center;
                font-size: 12px;
                color: black;
            }
        }
    }

    .add {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        font-size: 12px;
    }

    .add a {
        color: var(--color-red);
    }
}

.cluster {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    font: var(--text-regular-small);
    border: 5px solid var(--color-white);

    &.cluster-s {
        background-color: var(--color-red);
    }

    &.cluster-m {
        background-color: var(--color-red);
    }

    &.cluster-l {
        background-color: var(--color-red);
    }

    &.events.cluster-s {
        background-color: var(--color-gray);
    }

    &.events.cluster-m {
        background-color: var(--color-gray);
    }

    &.events.cluster-l {
        background-color: var(--color-gray);
    }
}



.message .hover {
    height: 0;
    display: none;
}

.activemarker {
    z-index:  400 !important;
}

.message:hover, .activemarker .message {
    border-radius: 25px !important;
}

.message:hover .hover, .activemarker .hover {
    /* height: 55px;
    transition: height 0.75s; */
}

.message .hover .meta, .message .hover .add a {
    opacity: 0;
}

.message:hover .hover .meta, .activemarker .hover .meta {
    /* transition-delay: 0.2s;
    opacity: 1 !important; */
}

.message:hover .add a, .activemarker .hover .add a{
    /* transition: 0;
    transition-delay: 0.35s;
    opacity: 1; */
}

.message .saver-badge {
    position: absolute;
    border-radius: 50%;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
}

.message .saver-badge img.saver-icon {
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.message.small .saver-badge {
    top: -3px;
    right: -3px;
    width: 15px;
    height: 15px;
}

.leaflet-popup-close-button {
    display: none;
}

.leaflet-popup-tip-container {
    margin-left:-25px;
}

.flagHolder {
    height: 30px;
}

.flag-box {
    z-index: -100 !important;
}

.flag-popup {
    bottom: 7px !important;
}

.flag {
    position: absolute;
    left: 5px;
    height: 20px;
    width: 30px;
    z-index: 1;
}

.flag-popup .leaflet-popup-content {
    width: 250px;
}

.leaflet-container .leaflet-marker-pane img {
    width: 30px;
    height: 30px;
}

.leaflet-container .leaflet-marker-pane .small img {
    width: 15px;
    height: 15px;
}

.leaflet-container .leaflet-marker-pane .event img
{
    width: 100%;
    height: 100%;
}

.cluster-box.event
{
    z-index: 1000 !important;
}

.leaflet-popup-content-wrapper {
    z-index: 3;

    .leaflet-popup-content {
        margin: 10px;
    }

    .image {
        width: 100%;
        border-radius: 8px;
    }

    .rowDescription {
        display: flex;
        align-items: center;

        .eventd {
            color: #C6D1D5;
            font-size: 12px;
        }


        .coordinates {
            font-size: 12px;
            color: #6F7E84;
        }
        .name {
            max-width: 250px;
            /* white-space: nowrap; */
            overflow: hidden;
            text-overflow: ellipsis;

            a {
                color: var(--color-red);
            }
        }
    }
}
