.map-box {
    flex: 1;
    position: relative;
}

.map {
    position: absolute !important;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: var(--color-gray-dark);
}
